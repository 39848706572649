import { useState, useEffect } from "react";

const useLocalStorageState = (initArg, key) => {
    const item = (typeof window === 'undefined') ? null : localStorage.getItem(key);
    const initState = item === null ? initArg : item;
    const [state, setState] = useState(initState);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        localStorage.setItem(key, state.toString());
    }, [state, key]);

    return [state, setState];
};

export default useLocalStorageState;
