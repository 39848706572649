import * as React from 'react';

const Footer = ({ className }) => (
    <footer className={`${className ? className : ''} flex justify-center py-4 bg-gray-700 text-gray-200 text-sm`}>
        <a
            className="p-4"
            href="https://thierry-schmidt.ch/"
            target="_blank"
            rel="noreferrer"
        >
            by Thierry Schmidt
        </a>
    </footer>
);

export default Footer;
