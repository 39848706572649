import * as React from 'react';
import useLocalStorageState from '../hooks/useLocalStorageState';

const CookieBanner = () => {
    const [hasAcceptedCookies, setHasAcceptedCookies] = useLocalStorageState(false, 'cookies');

    const acceptCookies = () => {
        setHasAcceptedCookies(true);
    }

    return (
        <>
            {!hasAcceptedCookies && (
                <div className='fixed z-20 bottom-0 left-0 right-0 bg-white p-4 rounded xl:bottom-auto xl:top-4 xl:right-auto xl:left-4 xl:py-2'>
                    <div className='flex gap-4 items-baseline justify-center'>
                        <p>🍪 Wir verwenden Cookies</p>
                        <button
                            className='underline'
                            onClick={acceptCookies}
                        >
                            Ok
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default CookieBanner;
