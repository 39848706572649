import * as React from 'react';
import { Helmet } from 'react-helmet';
import "@fontsource/poppins"; // Defaults to weight 400.
import "@fontsource/poppins/600.css";
import Footer from './footer';
import CookieBanner from './cookie-banner';

const Layout = ({ children }) => (
    <>
        <Helmet
            htmlAttributes={{
                lang: 'de',
            }}
        >
        </Helmet>
        <div className="min-h-screen flex flex-col">
            <main className="font-sans" style={{ flex: '1 0 auto' }}>
                {children}
            </main>
            <Footer className="flex-shrink-0" />
            <CookieBanner />
        </div>
    </>
);

export default Layout;
